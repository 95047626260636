import React, { useEffect, useState } from 'react'
import { useSetRecoilState } from 'recoil'
import { navigate } from 'gatsby'
import { Row, Col } from 'react-bootstrap'
import Layout from '/src/components/layout'
import { Button } from '/src/components/common'
import { userState } from '/src/hooks/useUser'
import { cartState, cartCountState, getCart } from '/src/hooks/useCart'
import { getRequest, postRequest } from '/src/httpClient'

const UserTradeOnboardPage = ({ params }) => {
  const { uid, timestamp, hash } = params
  const setUserState = useSetRecoilState(userState)
  const setCartState = useSetRecoilState(cartState)
  const setCartCount = useSetRecoilState(cartCountState)
  const [canLogin, setCanLogin] = useState(false)
  const [tosStatus, setTosStatus] = useState(false)

  useEffect(() => {
    postRequest('/api/user/validate', {
      uid: uid,
      timestamp: timestamp,
      hash: hash
    }).then((res) => {
      if (res) {
        getRequest('/api/user', new AbortController()).then(userRes => {
          setUserState(userRes)

          getCart().then(cartRes => {
            if (!cartRes) {
              setCanLogin(true)
              return
            }

            const { order_items } = cartRes.relationships
            setCartState(cartRes)

            let cartQuantity = 0
            if (order_items.data.length !== 0) {
              cartQuantity = order_items.data.map(({ attributes }) => {
                return attributes.quantity
              }).reduce(
                (a, c) => a + parseInt(c),
                0
              )
            }
            setCartCount(cartQuantity)
            setCanLogin(true)
          })
        })
      }
    })
  }, [])

  const loginHandler = () => {
    if (canLogin) {
      navigate('/user')
    }
  }

  return (
    <Layout>
      <Row className="mt-5">
        <Col xs={12}>
          <div className="title-container">
            <h1 className="title">Password Reset Request</h1>
          </div>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col xs={12}>
          <p>By clicking login you agree to our terms and conditions, privary policy and conditions of sale.</p>
          <p>This login link is only valid for 24 hours.</p>
          <Button variant="primary" onClick={() => loginHandler()}>Login</Button>
        </Col>
      </Row>
    </Layout>
  )
}

export default UserTradeOnboardPage

export const Head = () => <title>Trade Onboard</title>
